import { BULL } from 'constants/theme';
import { ABOUT_PATH, BLOG_PATH } from 'constants/paths';

import React from 'react';
import { graphql } from 'gatsby';
import readingTime from 'reading-time';

import Layout from 'components/layout';
import Head from 'components/layout/head';
import { Row, Column } from 'components/grid';
import Spacer from 'components/spacer/spacer';
import BlogItem from 'components/blog/blogItem/blogItem';
import MoreLink from 'components/moreLink/moreLink';
import { BlogPost } from 'components/blog';
import { getBlogURL } from 'components/blog/utils';

interface Props {
  data: {
    allMarkdownRemark: {
      edges: BlogPost[];
    };
  };
}

const Index: React.FC<Props> = ({ data }) => {
  const singlePost = data.allMarkdownRemark.edges[0];

  return (
    <Layout type="home">
      <Head pageTitle="Full-Stack Developer" />

      <Row>
        <Column tag="section" size={5}>
          <h1>
            <span className="text-primary">Hi,</span>
            <br />
            I am Angelo
            <br />
            Developer &amp; Speaker
          </h1>
          <h2 className="h3 text-muted">
            Full-Stack Developer {BULL} Python {BULL} React
          </h2>
          <p>
            I’ve always been drawn to development, studying &amp; teaching. I
            enjoy each aspect and love building complex sites or apps from start
            to finish.
          </p>
          <p className="text-light">
            I’m working at <a href="https://www.divio.com/">Divio</a>, which
            helps continuously build and deploy web apps across clouds.
          </p>

          <Spacer />

          <MoreLink to={ABOUT_PATH} text="View More About Me" style="success" />
        </Column>

        <Column tag="section" size={5}>
          <Spacer size={1.5} />

          <h2 className="h1">Blog</h2>
          <BlogItem
            variant="home"
            category={singlePost.node.frontmatter.category}
            title={singlePost.node.frontmatter.title}
            date={singlePost.node.frontmatter.date}
            readingTime={readingTime(singlePost.node.rawMarkdownBody).minutes}
            image={singlePost.node.frontmatter.cover}
            to={getBlogURL(singlePost.node.fields.slug)}
          />

          <MoreLink
            to={BLOG_PATH}
            text="View More Articles"
            style="secondary"
          />
        </Column>
      </Row>
    </Layout>
  );
};

export const indexQuery = graphql`
  {
    allMarkdownRemark(
      limit: 1
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            category
            title
            date(formatString: "DD. MMMM, YYYY")
            cover {
              childImageSharp {
                gatsbyImageData(width: 494, layout: FIXED)
              }
            }
          }
          rawMarkdownBody
        }
      }
    }
  }
`;

export default Index;
