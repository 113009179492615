import React from 'react';

import './spacer.scss';

interface Props {
  size?: number;
  id?: string;
}

const Spacer: React.FC<Props> = ({ size = 1, ...rest }) => (
  <div
    className="spacer"
    style={{ margin: `${size * 26}px 0` }}
    {...rest}></div>
);

export default Spacer;
